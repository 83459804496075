import React from "react";
import Layout from "../components/Layout";
import bakingbad1 from "../assets/images/baking-bad.webp";
import bakingbad2 from "../assets/images/baking-bad_2.jpeg";
import infusionmax1 from "../assets/images/infusionmax.jpeg";
import miami1 from "../assets/images/miami1.jpeg";
import miami2 from "../assets/images/miami2.jpeg";

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Europe</h2>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">BAKING BAD</h3>
          <p>
            Introducing the all-new "Baking Bad Cream Charger Tanks" 580g, 640g
            & 2kg!– your essential partner for culinary excellence in the world
            of catering and hospitality. These cutting-edge cream charger tanks
            are now available in Europe, setting a new standard for precision,
            convenience, and quality in the kitchen.
          </p>

          <img width="50%" src={bakingbad1} alt="" />
          <img src={bakingbad2} width="50%" alt="" />

          <h4 className="major">Key Features</h4>
          <p>
            1.<strong>Premium Quality:</strong>Crafted to the highest standards,
            Baking Bad Cream Charger Tanks ensure the purest and freshest
            whipped cream every time. Made from food-grade materials, they
            guarantee both safety and exceptional taste.
          </p>

          <p>
            2.<strong>Precision Engineering:</strong> Designed for ease of use
            and consistency, these tanks feature advanced pressure-regulating
            mechanisms. Achieve perfect results with every dispense, whether
            it's for desserts, beverages, or savory dishes.
          </p>
          <p>
            3.<strong>High Capacity:</strong>With a generous capacity, these
            tanks are ideal for busy catering operations. Fewer refills mean
            more time spent delighting your customers with exquisite creations.
          </p>
          <p>
            4.<strong>Easy Handling:</strong>The user-friendly design ensures
            straightforward operation. Attach, dispense, and detach
            effortlessly, saving time and reducing hassle in the kitchen.
          </p>
          <p>
            5.<strong>Versatility:</strong>Baking Bad Cream Charger Tanks are
            versatile enough to cater to a wide range of culinary needs. From
            classic whipped cream to inventive foams and sauces, they empower
            chefs to push the boundaries of creativity.
          </p>
          <p>
            6.<strong>Sustainable Choice:</strong>We care about the environment.
            These tanks are eco-friendly, designed to minimize waste and
            maximize efficiency. Make a responsible choice without compromising
            on quality.
          </p>
          <p>
            6.<strong>European Availabilty:</strong>Now available across Europe,
            Baking Bad Cream Charger Tanks offer quick access to this essential
            kitchen tool, ensuring that you can always meet the demands of your
            clientele.
          </p>
          <p>
            Elevate your culinary creations and impress your guests with the
            precision and reliability of Baking Bad Cream Charger Tanks. Make
            every dish an unforgettable masterpiece. Order yours today and take
            your catering and hospitality experience to the next level!
          </p>

          <h3 className="major">INFUSIONMAX</h3>

          <p>
            Introducing the "Infusion Max Cream Charger Tanks" - the
            game-changing infusion solution for the dynamic world of hospitality
            industries across Europe. Elevate your offerings and craft
            unforgettable experiences for your patrons with these cutting-edge
            infusion tanks.
          </p>

          <img width="50%" src={infusionmax1} alt="" />
      
          <h4 className="major">Key Features</h4>

          <p>
            1.<strong>Precision Infusions:</strong>Infusion Max Cream Charger
            Tanks redefine the art of flavor infusion. Designed for mixologists
            and chefs alike, they provide unparalleled precision, ensuring every
            infusion is a masterpiece.
          </p>
          <p>
          2.<strong>Ample Capacity:</strong>With generous tank capacity, you can cater to high-demand situations without constant refilling. This efficiency saves valuable time and keeps the service flowing smoothly.
        </p>
      
        <p>
      3.<strong>Effortless Operation:</strong>Simple and user-friendly, these tanks are designed for quick setup and seamless operation, allowing your staff to focus on creativity and customer satisfaction.
      </p>
    
      <p>
      4.<strong>Diverse Culinary Applications:</strong>Infusion Max opens the door to a world of culinary creativity. From craft cocktails with unique flavor profiles to culinary dishes that surprise and delight, these tanks are the key to innovation.
    </p>
    <p>
    5.<strong>Premium Materials:</strong>Crafted from food-grade materials, these tanks prioritize safety and quality, ensuring that your infusions meet the highest standards in taste and hygiene.
  </p>
  <p>
  6.<strong>Sustainable Approach:</strong>In alignment with responsible hospitality practices, Infusion Max Cream Charger Tanks are eco-conscious, minimizing waste and promoting sustainable service.
</p>
<p>
7.<strong>European Availability:</strong>Now readily available throughout Europe, Infusion Max tanks enable your establishment to stay ahead of the curve, delighting your clientele with extraordinary, infused offerings..
</p>

<p>
Transform your hospitality business into a hub of innovation and sensory delight. With Infusion Max Cream Charger Tanks, you have the power to create memorable, unique experiences that keep patrons coming back for more. Elevate your hospitality game today – order your tanks and start infusing enchantment into every sip and bite!
</p>

<h3 className="major">Miami Magics</h3>

<h4 className="major">🌴 Introducing the Miami Magic Cream Chargers! 🧁✨</h4>
<p>
Get ready to elevate your dessert game with our brand new Miami Magic 580g, 640g & 2kg cream charger tanks! 🎉🍨 These sleek and stylish chargers are here to add a touch of enchantment to your culinary creations. 🌈✨
</p>

<img src={miami1} width="50%"  alt="" />
<img src={miami2} width="50%" alt="" />

<h4 className="major">Key Features</h4>

<p>
  1.<strong>Experience the Magic: </strong>Transform your ordinary desserts into extraordinary delights with a dollop of creamy perfection. Whether it's fluffy whipped cream on top of your favorite cake or a heavenly mousse to satisfy your sweet tooth, the Miami Magic Cream Chargers will bring pure magic to your kitchen! 🎩🍰
</p>
<p>
2.<strong>Perfect Whips, Every Time:</strong>Our chargers are designed to deliver consistent, high-quality results. Achieve perfectly whipped cream with just the right texture and density, making your desserts look and taste absolutely irresistible! 😍🍦
</p>

<p>
3.<strong> Safe and Reliable:</strong>We prioritize your safety without compromising on performance. Our Miami Magic Cream Chargers are made from premium materials and undergo rigorous quality checks to ensure they meet the highest standards. Trust us to provide you with a reliable and secure solution for all your cream whipping needs. 🛡️✅
</p>

<p>
4.<strong>Easy to Use:</strong>Say goodbye to complicated preparations! Simply attach the charger to your cream dispenser, give it a gentle shake, and let the magic unfold. It's quick, hassle-free, and allows you to focus on the art of creating delectable desserts. 🎨🍮
</p>
<p>
5.<strong>Environmentally Friendly:</strong>Friendly: We care about our planet just as much as we care about your satisfaction. The Miami Magic Cream Chargers are recyclable, so you can enjoy your whipped cream guilt-free, knowing you're making an eco-friendly choice. 🌍🌱
</p>
<p>
6.<strong>Share the Magic:</strong>Tag us in your culinary adventures and let the world witness your extraordinary creations. Show off your stunning desserts, get inspired by fellow foodies, and join the Miami Magic community! ✨📸
</p>
<p>
👉 Grab your Miami Magic Cream Chargers today and unlock a world of dessert enchantment! 🧁✨ Available now at your favorite kitchen supply stores or online. Don't miss out on this magical experience! 🌟💕
</p>

        
    
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
